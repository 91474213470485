import React, { Component } from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <Layout
                title="Privacy Policy | HomeSpotter"
                description="Your privacy is important to us. This policy explains our information practices, defines your privacy options and describes how your information is collected and used by us."
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany privacy-policy">
                    <h1>Privacy Policy</h1>
                    <p>
                        Your privacy is important to us. This policy explains
                        our information practices, defines your privacy options
                        and describes how your information is collected and used
                        by us, HomeSpotter, LLC ("HomeSpotter").
                    </p>
                    <p>
                        HomeSpotter has developed certain web capabilities and
                        related mobile phone applications that provide users
                        with real estate information and/or tools pertaining to
                        real estate. This privacy policy covers all of
                        HomeSpotter websites and mobile phone applications,
                        which include, but are not limited to, the website(s) at{' '}
                        <a href="https://homespotter.com/">
                            https://homespotter.com/
                        </a>
                        , <a href="https://spac.io">https://spac.io</a>, and the
                        HomeSpotter applications for iPhone, iPad, and Android,
                        and specifically includes any branded website(s) or
                        applications we have developed on behalf of our
                        partners, such as multiple listing services ("MLSs"),
                        associations, brokers, agents, or other enterprises
                        (collectively the "HS Sites").
                    </p>
                    <p>
                        By using or accessing the HS Sites you agree to the
                        collection and use of information in the manner
                        described in this policy. HomeSpotter shall have the
                        right at any time and without prior notice, at its sole
                        discretion, to revise this policy. Such revisions and
                        additions shall be effective immediately upon notice,
                        which may be given by any means, including but not
                        limited to posting the revised or additional terms and
                        conditions on HS Sites. You are responsible for
                        reviewing the HS Sites periodically for any modification
                        to this policy.
                    </p>
                    <h2>THE INFORMATION WE COLLECT</h2>
                    <p>
                        The HS Sites are powered by real estate information
                        collected and compiled from various sources. The more
                        you post and share your comments, flags, likes, links,
                        photos, saved searches, and recommendations on the HS
                        Sites, the more interesting, relevant and informative,
                        in our humble opinion, the collective power and wisdom
                        of the HS Sites will become.
                    </p>
                    <p>
                        This policy applies to all information collected on the
                        HS Sites and any information you provide to HomeSpotter.
                        You will provide us with your personally identifiable
                        information when you register as an HS Sites user, use
                        any of HS Sites features or tools or participate in
                        certain HomeSpotter sponsored activities. The personal
                        information we collect when you register may include:
                        your name; your email address; the country in which you
                        reside; your zip code; your birthday; your gender; your
                        address; your location; your phone number; and a user ID
                        and password. The information you submit to HomeSpotter
                        may also include your text, photographs, video,
                        graphics, music and any other materials you might submit
                        as part of, or in conjunction with, your use of the HS
                        Sites.
                    </p>
                    <h2>COMMUNICATIONS WITH HOMESPOTTER</h2>
                    <p>
                        The HS Sites may include areas that allow you to post
                        your comments, flags, likes, links, photos, saved
                        searches, and recommendations. In some cases, you can
                        post such comments, flags, likes, links, photos, saved
                        searches, and recommendations on an anonymous basis or
                        for your private benefit. In other cases, you may
                        choose, or you must link such comments, flags, likes,
                        links, photos, saved searches, and recommendations to a
                        display name or photograph you have selected and/or
                        provided to HomeSpotter. In addition, you will have the
                        ability to post personally identifiable information to
                        the HS Sites. We urge you to exercise caution when
                        providing personally identifiable information, in areas
                        that the HS Sites provides for your comments, flags,
                        likes, links, photos, saved searches, and
                        recommendations. Remember that any personally
                        identifiable information you disclose in the public
                        areas of the HS Sites can be viewed by other users of
                        the HS Sites.
                    </p>
                    <h2>OUR COLLECTION OF YOUR DATA</h2>
                    <p>
                        In addition to the personal information you supply, we
                        may collect certain information to evaluate how you and
                        other users use the HS Sites. Also, we collect data to
                        make our service work better for you in the following
                        ways: to improve the design of the HS Sites, to provide
                        personalization on the HS Sites, and to evaluate the
                        performance of our various activities and programs. The
                        technologies we may use to gather this non-personal
                        information may include IP addresses, cookies, clear
                        gifs, browser detection, current GPS location, and
                        weblogs.
                    </p>
                    <h2>HOW WE USE INFORMATION</h2>
                    <p>
                        Our primary goal in collecting your information is to
                        provide you with a personalized, relevant, and positive
                        experience with the HS Sites.
                    </p>
                    <p>
                        You may register on the HS Sites to receive promotions
                        and updates, or to be contacted for other activities and
                        purposes. The contact information you submit will not be
                        used for these purposes unless you specifically agree to
                        receive such communications. You can control your
                        privacy preferences regarding such communications (see
                        the section below entitled "Your Privacy Preferences").
                    </p>
                    <p>
                        From time to time, you may be invited to participate in
                        HomeSpotter sponsored activities or programs such as
                        surveys, contests, user feedback and incentive programs.
                        HomeSpotter may request that you provide some or all of
                        the above listed personal information to participate in
                        those activities or programs. We use information
                        collected from activities and programs to learn about
                        users in order to improve our services and develop new
                        products and services of interest to our customers.
                    </p>
                    <p>
                        If you choose to use our referral service to tell a
                        friend, email a friend, refer a friend, send to a
                        friend, email a page or invite a friend about the HS
                        Sites, we may ask you for your friend’s name and email
                        address. We will automatically send your friend a
                        one-time email or text message inviting him or her to
                        visit the HS Sites. HomeSpotter stores this information
                        for the sole purpose of sending this one-time email or
                        text message and tracking the success of our referral
                        program.
                    </p>
                    <p>
                        HomeSpotter may use IP addresses, cookies, clear gifs,
                        browser detection, current GPS location, and weblogs to
                        provide a better experience on our website and to give
                        us useful information.
                    </p>
                    <p>
                        IP addresses define the Internet location of computers
                        and help us better understand the geographic
                        distribution of users and website visitors and manage
                        the performance of the HS Sites. Cookies are tiny files
                        placed onto the hard drive of your computer when you
                        visit the HS Sites, so we can immediately recognize you
                        when you return to the HS Sites and deliver content
                        specific to your interests. You may modify your browser
                        preferences to accept all cookies, be notified when a
                        cookie is set, or reject all cookies. Please consult
                        your browser instructions for information on how to
                        modify your choices about cookies.
                    </p>
                    <p>
                        We may also use a technology called clear gifs. Clear
                        gifs are tiny graphics with unique identifiers that are
                        used to track emails and online movements, helping us
                        understand what activities and content are effective.
                    </p>
                    <p>
                        We may detect the type of web browser you are using to
                        optimize the performance of the HS Sites and to
                        understand the mix of browsers used by our users and
                        visitors. To learn about how people use our website, we
                        examine weblogs, which show the paths people take
                        through our site and how long they spend in certain
                        areas.
                    </p>
                    <p>
                        We may detect if the device you are using to access the
                        HS Sites has a unique identifier to uniquely identify
                        the device. We may use this unique identifier so we can
                        immediately recognize you when you return to the HS
                        Sites and deliver content specific to your interests.
                    </p>
                    <p>
                        The HS Sites are powered, in part, by the GPS
                        functionality on your mobile phone. If you use the
                        mobile phone applications that are part of the HS Sites,
                        HomeSpotter will know the location of your phone when
                        you access such mobile phone application, the type of
                        phone that you are using to access such mobile phone
                        applications and the real estate information that you
                        choose to review.
                    </p>
                    <p>
                        We will share your personal information with third
                        parties only in the ways that are described in this
                        privacy statement.
                    </p>
                    <p>
                        We may provide your personal information and/or your
                        comments, flags, likes, links, photos, saved searches,
                        and recommendations to the MLSs, associations, brokers,
                        agents, or other enterprises for which we have branded
                        the HS Sites, especially for the purposes of evaluating
                        a potential real estate transaction.
                    </p>
                    <p>
                        We may provide your personal information to companies
                        that provide services to HomeSpotter. These companies
                        are authorized to use your personal information only as
                        necessary to provide these services to us. HomeSpotter
                        may also contract with unaffiliated third parties to
                        provide services, such as software for the HS Sites
                        features, customer communications, analyzing and
                        evaluating the information we collect, and other
                        services. When we do this, we may provide your
                        personally identifiable information to third parties
                        only to provide those services, and they are not
                        authorized to use your personally identifiable
                        information for any other purpose. We may also provide
                        to third parties on a non-personal basis the opinions,
                        recommendations and preferences you have posted at the
                        HS Sites. Such information may be used, for example, by
                        advertisers working with HomeSpotter to provide you with
                        web based advertising and promotions (both on the HS
                        Sites and elsewhere) that are based, in part, on the
                        comments, flags, likes, links, photos, saved searches,
                        and recommendations you have posted on the HS Sites. The
                        information provided to such a third party shall not
                        identify you personally as the source of the comments,
                        flags, likes, links, photos, saved searches, and
                        recommendations.
                    </p>
                    <p>
                        We partner with third party ad networks to display or
                        manage advertising on the HS Sites. Our ad networks
                        partners use cookies and Web beacons to collect
                        non-personally identifiable information about your
                        activities on this and other Web sites to provide you
                        targeted advertising based upon your interests.
                    </p>
                    <h2>OUR COMMITMENT TO DATA SECURITY</h2>
                    <p>
                        The security of your personal information is important
                        to us. We follow generally accepted standards to protect
                        the personal information submitted to us, both during
                        transmission and upon receipt. No method of transmission
                        over the Internet, or method of electronic storage, is
                        100% secure, however. Therefore, we cannot guarantee its
                        absolute security. If you have any questions about
                        security on the HS Sites, you can contact us at
                        privacy@homespotter.com.
                    </p>
                    <h2>USE OF THE HS SITES BY CHILDREN</h2>
                    <p>
                        The HS Sites are not intended for use by children.
                        HomeSpotter will not knowingly collect any personally
                        identifiable information submitted by visitors to the HS
                        Sites who are under the age of 13.
                    </p>
                    <h2>EMAIL MARKETING</h2>
                    <p>
                        HomeSpotter may provide or make available to users
                        marketing communications such as e-mail newsletters,
                        product and service updates and promotions. HomeSpotter
                        users generally find this type of information useful.
                    </p>
                    <p>
                        If you don’t want to receive such communications, you
                        must "opt-out" by checking the "Remove me from the
                        mailing list" box on the communications registration
                        page on the HS Sites. Should you choose to not receive
                        such future communications, you can use any of the
                        following three methods to notify HomeSpotter: select
                        the "unsubscribe" link at the bottom of the email sent
                        by HomeSpotter and follow the instructions; go to your
                        user page, select "Manage Account", then select "Change
                        Email Preferences" and select the appropriate box; or
                        send an email to unsubscribe@homespotter.com or
                        physically mail your information to: Attn: Customer
                        Service, HomeSpotter LLC, 400 S 4th St #109,
                        Minneapolis, MN 55415 (Please include your email address
                        you wish to unsubscribe).
                    </p>
                    <h2>HOW TO ACCESS OR CORRECT YOUR INFORMATION</h2>
                    <p>
                        You can access and maintain your personally identifiable
                        information on your user information page on the HS
                        Sites. To protect your privacy and security, we require
                        a user ID and password to verify your identity before
                        granting access to or making corrections to your user
                        information page.
                    </p>
                    <h2>
                        DISCLOSURE OF YOUR PERSONALLY IDENTIFIABLE INFORMATION
                    </h2>
                    <p>
                        We reserve the right to disclose your personally
                        identifiable information as required by law and when we
                        believe that disclosure is necessary to protect our
                        rights and/or comply with a judicial proceeding, court
                        order or legal process. It is also possible that
                        HomeSpotter would sell all or substantially all of its
                        assets and business. In any transaction of this kind,
                        customer information, including your personally
                        identifiable information, may be among the assets that
                        are transferred. If we decide to so transfer your
                        personally identifiable information, you will be
                        notified by email or a post to the HS Sites.
                    </p>
                    <h2>LEGAL DISCLOSURES</h2>
                    <p>
                        Some state laws and governing regulations grant covered
                        citizens certain rights regarding their data, including:
                    </p>
                    <ul>
                        <li>
                            The right to know the categories of personal
                            information HomeSpotter collects (see table below -
                            CATEGORIES OF PERSONAL INFORMATION)
                        </li>
                        <li>
                            The right to know the categories of sources from
                            which HomeSpotter collects personal information (see
                            table below - CATEGORIES OF PERSONAL INFORMATION)
                        </li>
                        <li>
                            The right to know the categories of third parties
                            with whom HomeSpotter shares personal information
                            (see table below - CATEGORIES OF PERSONAL
                            INFORMATION)
                        </li>
                        <li>
                            The right to know the business or commercial purpose
                            for collecting personal information. (see section
                            below - BUSINESS OR COMMERCIAL PURPOSES FOR
                            COLLECTING PERSONAL INFORMATION)
                        </li>
                        <li>
                            The right to access the specific pieces of personal
                            information HomeSpotter has collected and the right
                            to delete personal information (see section below -
                            EXERCISING YOUR RIGHT TO ACCESS OR DELETE)
                        </li>
                        <li>
                            The right to know the categories of personal
                            information HomeSpotter sells to a third party. (see
                            section below - PERSONAL INFORMATION SALE - OPT OUT
                            AND RIGHTS)
                        </li>
                        <li>
                            The right to opt-out of sale of personal information
                            (see section below - PERSONAL INFORMATION SALE - OPT
                            OUT AND RIGHTS)
                        </li>
                    </ul>
                    <p>
                        In addition, some state laws and governing regulations
                        grant covered citizens the right not to be discriminated
                        against for exercising any of their applicable rights.
                    </p>
                    <h2>CATEGORIES OF PERSONAL INFORMATION</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th>Source</th>
                                <th>Sharing</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Identifiers <br />
                                    (Name, Email Address, Account Name or
                                    Username, Phone Number, IP Address. We also
                                    generate identifiers internally)
                                </td>
                                <td>
                                    Provided directly by customer, or provided
                                    by the customer’s agent
                                </td>
                                <td>
                                    We share this data with our service
                                    providers and our business partners
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Personal Information
                                    <br />
                                    (Name, Telephone number)
                                </td>
                                <td>
                                    Provided directly by customer, or added by
                                    their agent
                                </td>
                                <td>
                                    We share this data with our service
                                    providers and our business partners
                                </td>
                            </tr>
                            <tr>
                                <td>Protected Classifications</td>
                                <td>We don’t collect this data</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>
                                    Commercial Information
                                    <br />
                                    (Products purchased, Products considered,
                                    Purchasing History, Purchasing Tendencies)
                                </td>
                                <td>Collected from the consumer</td>
                                <td>
                                    We share this data with our service
                                    providers
                                </td>
                            </tr>
                            <tr>
                                <td>Biometric Information</td>
                                <td>We don’t collect this data</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>
                                    Internet or other Network Activity
                                    Information
                                    <br />
                                    (Cookies, Pixels, Interaction, Activity)
                                </td>
                                <td>
                                    Collected from consumer’s browser or device
                                </td>
                                <td>
                                    We share this data with our service
                                    providers
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Internet or other Network Activity
                                    Information
                                    <br />
                                    (Cookies, Pixels, Interaction)
                                </td>
                                <td>
                                    Collected from consumer’s browser or device
                                </td>
                                <td>
                                    We share this data with our service
                                    providers
                                </td>
                            </tr>
                            <tr>
                                <td>Geolocation Data</td>
                                <td>Consumer’s device</td>
                                <td>We don't share this data</td>
                            </tr>
                            <tr>
                                <td>
                                    Audio, electronic, visual, thermal,
                                    olfactory, or similar information
                                </td>
                                <td>We don’t collect this data</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>
                                    Professional or employment-related
                                    information
                                </td>
                                <td>We don’t collect this data</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>Education Information</td>
                                <td>We don’t collect this data</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>Inferences</td>
                                <td>
                                    Inferred from information provided by
                                    customer and information collected
                                    indirectly from customer’s browser or device
                                </td>
                                <td>We don't share this data</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>
                        BUSINESS OR COMMERCIAL PURPOSES FOR COLLECTING PERSONAL
                        INFORMATION
                    </h2>
                    <p>
                        The business or commercial purposes for HomeSpotter
                        collecting personal information:
                    </p>
                    <ul>
                        <li>
                            Performing services on behalf of the business or
                            service provider, including maintaining or servicing
                            accounts, providing customer service, processing or
                            fulfilling orders and transactions, verifying
                            customer information, processing payments, providing
                            financing, providing advertising or marketing
                            services, providing analytic services, or providing
                            similar services on behalf of the business or
                            service provider.
                        </li>
                        <li>
                            Undertaking internal research for technological
                            development and demonstration.
                        </li>
                        <li>
                            Debugging to identify and repair errors that impair
                            existing intended functionality.
                        </li>
                    </ul>
                    <h2>EXERCISING YOUR RIGHT TO ACCESS OR DELETE</h2>
                    <p>
                        Some state laws and governing regulations grant covered
                        citizens right to access the personal information
                        HomeSpotter has collected about them or delete all of
                        their personal information collected by HomeSpotter. You
                        can do this by filling out{' '}
                        <Link to="/ccpa-request-form">this form</Link>. Only
                        you, or an authorized agent registered as required with
                        the applicable governing body that you authorize to act
                        on your behalf, may make a consumer request related to
                        your personal information. If you use an authorized
                        agent, you must provide us with written authorization or
                        a power of attorney, signed by you, for the agent to act
                        on your behalf. You will still need to verify your
                        identity directly with us.You may also make a consumer
                        request on behalf of your minor child (12 and under). If
                        you have any questions about your access or deletion
                        rights described above, you may also contact us via
                        email or regular mail at the contact information below
                        (see header - HOW TO CONTACT US).
                    </p>
                    <p>
                        Please note that you may only make a data access request
                        twice in a 12-month period.
                    </p>
                    <p>
                        If HomeSpotter is unable to verify your identity or your
                        authority to make the request, we will be unable to
                        respond to your access or deletion request. We will only
                        use the information provided by you in the form to
                        verify your identity and/or authority to make the
                        request.
                    </p>

                    <h2>RESPONSE TIMING AND FORMAT</h2>
                    <p>
                        If we are able to verify your identity and authority to
                        make the request, we will respond to the provided email
                        address within 45 days of receipt of request. If we
                        require extra time, we will inform you of the reason and
                        extension period.
                    </p>
                    <p>
                        Any data we disclose will only cover the 12-month period
                        preceding the receipt of verified consumer request.
                    </p>
                    <h2>PERSONAL INFORMATION SALE - OPT OUT AND RIGHTS</h2>
                    <p>
                        HomeSpotter does not disclose any of your personal
                        information in a way that would be considered a sale
                        under applicable state privacy laws.
                    </p>
                    <h2>PRIVACY AND LINKS TO THIRD PARTY WEBSITES</h2>
                    <p>
                        The HS Sites contain links to other websites.
                        HomeSpotter is not responsible for the privacy practices
                        of these other sites. We encourage you to be aware when
                        you leave our site, and to read the privacy statements
                        of each web site that collects personally identifiable
                        information. Some linked sites may be co-branded with
                        our trademarks and those of our business partners. They
                        may have the look and feel of the HS Sites, but the URL
                        in your browser window will indicate that you are
                        accessing a different site. This policy only applies to
                        information collected by HomeSpotter.
                    </p>
                    <p>
                        You may be able to log in to the HS Sites using sign-in
                        services such as Facebook Connect or an Open ID
                        provider. These services will authenticate your identity
                        and provide you the option to share certain personal
                        information with us such as your name and email address
                        to pre-populate our sign up form. Services like Facebook
                        Connect give you the option to post information about
                        your activities on this Web site to your profile page to
                        share with others within your network.
                    </p>
                    <p>
                        If you use the "Post to Facebook" check box to share
                        something, that item will appear on your Facebook
                        profile page and also on your friends’ newsfeed,
                        depending on your Facebook privacy settings. You may
                        also receive updates in your Facebook newsfeed from this
                        site or item in the future.
                    </p>
                    <h2>NOTIFICATION OF CHANGES</h2>
                    <p>
                        We may update this privacy statement to reflect changes
                        to our information practices. If we make any material
                        changes we will notify you by email (sent to the e-mail
                        address specified in your account) or by means of a
                        notice on this HS Sites prior to the change becoming
                        effective. We encourage you to periodically review this
                        page for the latest information on our privacy
                        practices.
                    </p>
                    <h2>HOW TO CONTACT US</h2>
                    <p>
                        Should you have privacy questions or concerns, please
                        send an email to privacy@homespotter.com, or mail to
                        HomeSpotter LLC, c/o Lone Wolf Real Estate Technologies,
                        Inc., attn: General Counsel, 701 Harwood Street, Suite
                        2200, Dallas, TX 75201.
                    </p>
                    <h2>USE OF HS SITES</h2>
                    <p>
                        Your use of the HS Sites is subject to the following
                        terms and conditions:{' '}
                        <a href="https://www.lwolf.com/about/terms-of-service">
                            https://www.lwolf.com/about/terms-of-service
                        </a>
                    </p>
                </div>
            </Layout>
        )
    }
}
