import React from 'react'
import SolutionNavigationRow from './SolutionNavigationRow'

const navLinks = {
    links: [
        {
            text: 'Integrations',
            href: '/integrations',
        },
        // { HOLD FOR V2
        //     text: 'Reviews',
        //     href: '/reviews',
        // },
        {
            text: 'Careers',
            href: '/careers',
        },
        {
            text: 'Contact',
            href: '/contact',
        },
    ],
}

export default function OurCompanyNavigationRow({ location }) {
    return (
        <SolutionNavigationRow
            type={{ title: 'Why HomeSpotter?', href: 'why-homespotter' }}
            location={location}
            {...navLinks}
        />
    )
}
