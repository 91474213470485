import React from 'react'
import { Link } from 'gatsby'

const activeTab = (href, location) => {
    let context = location.pathname
        .split('/')
        .filter(segment => segment !== '')
        .slice(-1)[0]

    return new RegExp(`${href}/?`).test(location.pathname)
        ? `active ${context}`
        : ''
}

export default function SolutionNavigationRow({ type, links, location }) {
    const navLinks = links.map(({ href, text }) => {
        const isBoostEverywhere = (href.includes('/agents/boost'));
        return (
            <li key={href}>
                {!isBoostEverywhere && <Link className={`${activeTab(href, location)}`} to={href}>
                    {text}
                </Link>}
                {isBoostEverywhere && <a href={`${href}`}>{text}</a>}
            </li>
        )
    })

    let link
    if (typeof type === 'object') {
        link = type.href
        type = type.title
    } else if (type === 'Brokerages & Franchisors') {
        link = 'brokers'
    } else if (type.toLowerCase() === 'mls') {
        // TODO Temporary until mls page is re-added.
        link = 'mls/connect'
    } else {
        link = type.toLowerCase()
    }

    return (
        <div className="solution-nav-row col-12">
            <div className="for-header">
                <Link
                    to={`/${link}`}
                    className={`${activeTab(link, location)}`}
                >
                    {type}
                </Link>
            </div>

            <ul>{navLinks}</ul>
        </div>
    )
}
